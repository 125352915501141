/* eslint-disable */
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import * as THREE from 'three';
import * as dat from 'dat.gui';
import apiKey from '@/../API-details.js';

import sunmap from '@/assets/images/sun_detailed.png';
import starfield from '@/assets/images/galaxy_starfield.png';
// // overkill textures
import earthmap from '@/assets/images/earth_10k.jpg';
import earthbump from '@/assets/images/dem.png';
import earthspec from '@/assets/images/earth_ocean_reflectance_4k.jpg';
import earthcloudmap from '@/assets/images/earth_clouds_fair_4k.png';
import earthcloudmaptrans from '@/assets/images/earthcloudmaptrans.jpg';
//
// standard textures
// import earthmap from '@/assets/images/earthmap1k.jpg';
// import earthbump from '@/assets/images/earthbump1k.jpg';
// import earthspec from '@/assets/images/earthspec1k.jpg';
// import earthcloudmap from '@/assets/images/earth_clouds_fair_4k.png';
// import earthcloudmaptrans from '@/assets/images/earthcloudmaptrans.jpg';
//

const earthAphelion = 1.0161 * 50;
//
const a_earth = 1 * 50; // semi-major axis
const e_earth = 0.01670 * 50; // eccentricity
const q_earth = 0.9826 * 50; // perihelion
// const b_earth = 0.99986 * 50; // semi-minor axis
const b_earth = (a_earth/50 * Math.sqrt(1 - (e_earth/50 * e_earth/50))) * 50; // semi-minor axis
const c_earth = a_earth - q_earth; // distance from center to focus (sun offset)

const asteroid_2 = {
  e: 0.3733732417415056,
  a: 1.234890210769517,
  q: 0.7738152495796512,
  i: 47.78155216336716,
  n: 127.3552248588245,
  w: 310.1672890376234,
};
const asteroid_3 = {
  e: 0.9009443619578256,
  a: 1.842233710802685,
  q: 0.1824836356463626,
  i: 12.77942247063064,
  n: 342.6787870561665,
  w: 14.80778872479496,
};
const asteroid_4 = {
  e: 0.3052775663247147,
  a: 0.76998265537135,
  q: 0.5349242242273428,
  i: 4.314550922788287,
  n: 68.58917960997324,
  w: 338.1004677588675,
};

// Credit to https://codepen.io/qkevinto/pen/EVGrGq for the original three.js environment to mess around in

// Scene, Camera, Renderer
let renderer = new THREE.WebGLRenderer();
let scene = new THREE.Scene();
let aspect = window.innerWidth / window.innerHeight;
let camera = new THREE.PerspectiveCamera(45, aspect, 0.1, 1500);
let cameraRotation = 0;
let cameraRotationSpeed = 0.001;
let cameraAutoRotation = false;
let orbitControls = new OrbitControls(camera, renderer.domElement);

// earth orbit
const curve1 = new THREE.EllipseCurve(
	0,  0,            // ax, aY
	a_earth, b_earth, // xRadius, yRadius
	0,  2 * Math.PI,  // aStartAngle, aEndAngle
	false,            // aClockwise
	0                 // aRotation
);
const points1 = curve1.getPoints( 300 );
const geometry1 = new THREE.BufferGeometry().setFromPoints( points1 );
geometry1.rotateX((0.5 + 0/180) * Math.PI) // x-rotation, 0.5pi puts it in the right plane, so add anything else to it
const material1 = new THREE.LineBasicMaterial( { color : 0x8dd2e7 } );
// Create the final object to add to the scene
const ellipse1 = new THREE.Line( geometry1, material1 );

function createAsteroidOrbit(asteroid) {
  const b = (asteroid.a * Math.sqrt(1 - (asteroid.e * asteroid.e))); // semi-minor axis
  const c = asteroid.a - asteroid.q; // distance from center to focus (sun offset)
  const curve = new THREE.EllipseCurve(
    0,  0,            // ax, aY
    asteroid.a * 50, b * 50,           // xRadius, yRadius
    0,  2 * Math.PI,  // aStartAngle, aEndAngle
    false,            // aClockwise
    0       // aRotation
  );
  const points = curve.getPoints( 300 );
  const geometry = new THREE.BufferGeometry().setFromPoints( points );
  geometry.rotateX(0.5 * Math.PI) // x-rotation, 0.5pi puts it in the right plane, so add anything else to it
  geometry.translate(-1 * c * 50, 0, 0); // move it so sun is at close Focus
  geometry.rotateY((1 * (asteroid.w + asteroid.n)/180) * Math.PI) // apply argument of periapsis
  geometry.rotateY((-1 * asteroid.n/180) * Math.PI) // turn it to apply inclination in the right plane
  geometry.rotateX(asteroid.i/180 * Math.PI) // apply inclination
  geometry.rotateY((1 * asteroid.n/180) * Math.PI) // turn it back
  const material = new THREE.LineBasicMaterial( { color : 0xd3d3d3 } );
  // Create the final object to add to the scene
  const ellipse = new THREE.Line( geometry, material );
  return ellipse;
}

// Lights
let spotLight = new THREE.SpotLight(0xffffff, 1, 0, 10, 2);
const ambientLight = new THREE.AmbientLight( 0x404040, 2); // soft white light
// const directionalLight = new THREE.DirectionalLight( 0xffffff, 0.5 );

// Texture Loader
let textureLoader = new THREE.TextureLoader();

// Planet Proto
let planetProto = {
  sphere: function(size) {
    let sphere = new THREE.SphereGeometry(size, 32, 32);
    
    return sphere;
  },
  material: function(options) {
    let material = new THREE.MeshPhongMaterial();
    if (options) {
      for (var property in options) {
        material[property] = options[property];
      } 
    }
    
    return material;
  },
  glowMaterial: function(intensity, fade, color) {
    // Custom glow shader from https://github.com/stemkoski/stemkoski.github.com/tree/master/Three.js
    let glowMaterial = new THREE.ShaderMaterial({
      uniforms: { 
        'c': {
          type: 'f',
          value: intensity
        },
        'p': { 
          type: 'f',
          value: fade
        },
        glowColor: { 
          type: 'c',
          value: new THREE.Color(color)
        },
        viewVector: {
          type: 'v3',
          value: camera.position
        }
      },
      vertexShader: `
        uniform vec3 viewVector;
        uniform float c;
        uniform float p;
        varying float intensity;
        void main() {
          vec3 vNormal = normalize( normalMatrix * normal );
          vec3 vNormel = normalize( normalMatrix * viewVector );
          intensity = pow( c - dot(vNormal, vNormel), p );
          gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
        }`
      ,
      fragmentShader: `
        uniform vec3 glowColor;
        varying float intensity;
        void main() 
        {
          vec3 glow = glowColor * intensity;
          gl_FragColor = vec4( glow, 1.0 );
        }`
      ,
      side: THREE.BackSide,
      blending: THREE.AdditiveBlending,
      transparent: true
    });
    
    return glowMaterial;
  },
  texture: function(material, property, uri) {
    let textureLoader = new THREE.TextureLoader();
    textureLoader.crossOrigin = true;
    textureLoader.load(
      uri,
      function(texture) {
        material[property] = texture;
        material.needsUpdate = true;
      }
    );
  }
};

let createPlanet = function(options) {
  // Create the planet's Surface
  let surfaceGeometry = planetProto.sphere(options.surface.size);
  let surfaceMaterial = planetProto.material(options.surface.material);
  let surface = new THREE.Mesh(surfaceGeometry, surfaceMaterial);
  
  // Create the planet's Atmosphere
  let atmosphereGeometry = planetProto.sphere(options.surface.size + options.atmosphere.size);
  let atmosphereMaterialDefaults = {
    side: THREE.DoubleSide,
    transparent: true
  }
  let atmosphereMaterialOptions = Object.assign(atmosphereMaterialDefaults, options.atmosphere.material);
  let atmosphereMaterial = planetProto.material(atmosphereMaterialOptions);
  let atmosphere = new THREE.Mesh(atmosphereGeometry, atmosphereMaterial);
  
  // Create the planet's Atmospheric glow
  let atmosphericGlowGeometry = planetProto.sphere(options.surface.size + options.atmosphere.size + options.atmosphere.glow.size);
  let atmosphericGlowMaterial = planetProto.glowMaterial(options.atmosphere.glow.intensity, options.atmosphere.glow.fade, options.atmosphere.glow.color);
  let atmosphericGlow = new THREE.Mesh(atmosphericGlowGeometry, atmosphericGlowMaterial);
  
  // Nest the planet's Surface and Atmosphere into a planet object
  let planet = new THREE.Object3D();
  surface.name = 'surface';
  atmosphere.name = 'atmosphere';
  atmosphericGlow.name = 'atmosphericGlow';
  planet.add(surface);
  planet.add(atmosphere);
  planet.add(atmosphericGlow);

  // Load the Surface's textures
  for (let textureProperty in options.surface.textures) {
    planetProto.texture(
      surfaceMaterial,
      textureProperty,
      options.surface.textures[textureProperty]
    ); 
  }
  
  // Load the Atmosphere's texture
  for (let textureProperty in options.atmosphere.textures) {
    planetProto.texture(
      atmosphereMaterial,
      textureProperty,
      options.atmosphere.textures[textureProperty]
    );
  }
  
  return planet;
};

let createStar = function(options) {
  // Create the star's Surface
  let surfaceGeometry = planetProto.sphere(options.surface.size);
  let surfaceMaterial = planetProto.material(options.surface.material);
  let surface = new THREE.Mesh(surfaceGeometry, surfaceMaterial);

  // Nest the star's Surface and Atmosphere into a planet object
  let star = new THREE.Object3D();
  surface.name = 'surface';
  star.add(surface);

  // Load the Surface's textures
  for (let textureProperty in options.surface.textures) {
    planetProto.texture(
      surfaceMaterial,
      textureProperty,
      options.surface.textures[textureProperty]
    );
  }

  return star;
};

let earth = createPlanet({
  surface: {
    size: 0.0000425875 * 2500,
    // size: 0.5,
    material: {
      bumpScale: 0.05,
      specular: new THREE.Color('grey'),
      shininess: 10,
    },
    textures: {
      map: earthmap,
      bumpMap: earthbump,
      specularMap: earthspec,
    },
  },
  atmosphere: {
    size: 0.003,
    material: {
      opacity: 0.8,
    },
    textures: {
      map: earthcloudmap,
      alphaMap: earthcloudmaptrans,
    },
    glow: {
      size: 0.0008,
      intensity: 0,
      fade: 7,
      color: 0x93cfef
    },
  },
});
let sun = createStar({
  surface: {
    size: 0.00465047 * 250,
    material: {
      bumpScale: 0,
      specular: new THREE.Color('yellow'),
      shininess: 30,
    },
    textures: {
      map: sunmap,
      bumpMap: sunmap,
      specularMap: sunmap,
    },
  },
});

// Galaxy
let galaxyGeometry = new THREE.SphereGeometry(500, 50, 50);
let galaxyMaterial = new THREE.MeshBasicMaterial({
  side: THREE.BackSide
});
let galaxy = new THREE.Mesh(galaxyGeometry, galaxyMaterial);

// Load Galaxy Textures
textureLoader.crossOrigin = true;
textureLoader.load(
  starfield,
  function(texture) {
    galaxyMaterial.map = texture;
    scene.add(galaxy);
  }
);

// Scene, Camera, Renderer Configuration
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

camera.position.set(1,10,25);
camera.lookAt(earth.position);
camera.translateX(q_earth);
camera.updateProjectionMatrix();
orbitControls.enabled = !cameraAutoRotation;
orbitControls.target = new THREE.Vector3(q_earth,0,0);
// orbitControls.target = earth.position;

scene.add(camera);
scene.add(spotLight);
scene.add(ambientLight);
// scene.add(directionalLight);
scene.add(earth);
earth.position.set(q_earth, 0, 0);
scene.add(ellipse1);
ellipse1.position.set(-1 * c_earth, 0, 0);
scene.add(createAsteroidOrbit(asteroid_2));
scene.add(createAsteroidOrbit(asteroid_3));
scene.add(createAsteroidOrbit(asteroid_4));
scene.add(sun);

// Light Configurations
// spotLight.position.set(2, 0, 1);
spotLight.target = earth;

// Mesh Configurations
earth.receiveShadow = true;
earth.castShadow = true;
earth.getObjectByName('surface').geometry.center();

// On window resize, adjust camera aspect ratio and renderer size
window.addEventListener('resize', function() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
});

// Main render function
let render = function() {
  earth.getObjectByName('surface').rotation.y += 1/32 * 0.01;
  sun.getObjectByName('surface').rotation.y += 1/32 * 0.005;
  earth.getObjectByName('atmosphere').rotation.y += 1/16 * 0.01;
  if (cameraAutoRotation) {
    cameraRotation += cameraRotationSpeed;
    camera.position.y = 0;
    camera.position.x = 2 * Math.sin(cameraRotation);
    camera.position.z = 2 * Math.cos(cameraRotation);
    camera.lookAt(earth.position);
  }
  requestAnimationFrame(render);
  renderer.render(scene, camera);
};

render();

// dat.gui
var gui = new dat.GUI();
var guiCamera = gui.addFolder('Camera');
var guiSurface = gui.addFolder('Surface');
var guiAtmosphere = gui.addFolder('Atmosphere');
var guiAtmosphericGlow = guiAtmosphere.addFolder('Glow');

// dat.gui controls object
var cameraControls = new function() {
  this.speed = cameraRotationSpeed;
  this.orbitControls = !cameraAutoRotation;
}

var surfaceControls = new function() {
  this.rotation = 0;
  this.bumpScale = 0.05;
  this.shininess = 10;
}

var atmosphereControls = new function() {
  this.opacity = 0.8;
}

var atmosphericGlowControls = new function() {
  this.intensity = 0;
  this.fade = 7;
  this.color = 0x93cfef;
}

// dat.gui controls
guiCamera.add(cameraControls, 'speed', 0, 0.1).step(0.001).onChange(function(value) {
  cameraRotationSpeed = value;
});
guiCamera.add(cameraControls, 'orbitControls').onChange(function(value) {
  cameraAutoRotation = !value;
  orbitControls.enabled = value;
});

guiSurface.add(surfaceControls, 'rotation', 0, 6).onChange(function(value) {
  earth.getObjectByName('surface').rotation.y = value;
});
guiSurface.add(surfaceControls, 'bumpScale', 0, 1).step(0.01).onChange(function(value) {
  earth.getObjectByName('surface').material.bumpScale = value;
});
guiSurface.add(surfaceControls, 'shininess', 0, 30).onChange(function(value) {
  earth.getObjectByName('surface').material.shininess = value;
});

guiAtmosphere.add(atmosphereControls, 'opacity', 0, 1).onChange(function(value) {
  earth.getObjectByName('atmosphere').material.opacity = value;
});

guiAtmosphericGlow.add(atmosphericGlowControls, 'intensity', 0, 1).onChange(function(value) {
  earth.getObjectByName('atmosphericGlow').material.uniforms.c.value = value;
});
guiAtmosphericGlow.add(atmosphericGlowControls, 'fade', 0, 50).onChange(function(value) {
  earth.getObjectByName('atmosphericGlow').material.uniforms.p.value = value;
});
guiAtmosphericGlow.addColor(atmosphericGlowControls, 'color').onChange(function(value) {
  earth.getObjectByName('atmosphericGlow').material.uniforms.glowColor.value.setHex(value);
});
