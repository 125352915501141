<template>
  <div>
  </div>
</template>

<script>

// useful links:
//
// 2440587.5 (days) is NASA epoch time of 1970-Jan-01.0, so NASA epoch time as unix timestamp would be '(dNASA - 2440587.5) * 86400'
//
import '@/assets/js/space-environment.js';

export default {
  name: 'App',
};
</script>

<style lang="scss">
@import "assets/css/minireset.css";
</style>
